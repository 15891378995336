<template>
    <div class="inSalabamMonographicBlock">
        <Spinner v-if="loading" />
        <div class="" v-else>
            <div v-for="(inSalabam,index) in (loadedInSalabamSliders.concat(allInSalabamSliders))" v-bind:key="_uid+'_monographic_'+index" :inSalabam="inSalabam">
                <div v-if="index<limit">
                    <InSalabamMonographicSlider
                        v-if="loadedInSalabamSliders[index]"
                        :inSalabam="loadedInSalabamSliders[index]"
                        />
                    <Spinner v-else />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '../../../api'
    import Spinner from '../../atoms/spinner'
    import InSalabamMonographicSlider from './in-salabam-monographic-slider'

    export default {
        name: 'inSalabamMonographicBlock',
        components: {
            Spinner,
            InSalabamMonographicSlider
        },
        props:
        {
            skip: {
                type: Array,
                default: () => new Array()
            },
            limit: {
                type: Number,
                default: 10,
            },
            category: {
              type: Object,
              default: () => { return {code: '', label: ''} },
            },
            skipCategory: {
              type: Object,
              default: () => { return {code: '', label: ''} },
            },
        },
        data() {
            return {
                loading: true,
                allInSalabamSliders: [],
                loadedInSalabamSliders: [],
            }
        },
        mounted()
        {
            let _this = this
                _this.loading = true

            Api.getInSalabamMonographic({
              categoryCode: this.category.code,
            })
            .then((data) => {
                if(data.results)
                {
                    let inSalabams = (data.results).filter(function(inSalabam) { return !_this.skip.includes(parseInt(inSalabam.inSalabamId)) })

                    if(this.skipCategory.code != ''){

                      inSalabams = inSalabams.filter(function(inSalabam) { return inSalabam.category.code != _this.skipCategory.code })

                    }

                    //_this.allInSalabamSliders = data.results
                    _this.allInSalabamSliders = inSalabams
                    _this.loadedInSalabamSliders = []
                    _this.loading = false
                    _this.getInsalabamPreview(_this.allInSalabamSliders[0])
                }
            })
        },
        destroyed()
        {
            // interrompo le chiamate non ancora effettuate
            this.allInSalabamSliders = []
        },
        methods:{
            getInsalabamPreview(inSalabam)
            {
                // gestisco caso in cui ho cambiato vista tra una chiamata e l'altra con destroyed()
                if(this.allInSalabamSliders.length == 0) return

                let _this = this
                Api.getPreview({
                    checkin: inSalabam.search.checkin,
                    checkout: inSalabam.search.checkout,
                    //nights: inSalabam.search.nights,  // non è mai stato utilizzato come parametro di ricerca per vadobay
                    occupancy: inSalabam.search.occupancy,
                    lat: inSalabam.geo.latitude,
                    lng: inSalabam.geo.longitude,
                    offset: 0, // fisso zero
                    matchLevel: inSalabam.geo.matchLevel,
                    label: inSalabam.geo.label,
                    searchCounter: 0, // fisso zero
                    salabamCategories: inSalabam.search.salabamCategories,
                    //routeName: Router.history.current.name,
                    inSalabamId: inSalabam.inSalabamId,
                    limit: inSalabam.search.limit, // limitazione numero risultati preview
                }).then((data) => {
                    _this.allInSalabamSliders.shift()
                    _this.loadedInSalabamSliders.push({
                        data: data,
                        infoPad: {
                            title: inSalabam.title,
                            abstract: inSalabam.abstract,
                            search: inSalabam.search,
                            geo: inSalabam.geo, // TODO GESTIRE REDIRECT SU MAPPA
                            inSalabamId: inSalabam.inSalabamId,
                        }
                    })
                    if(_this.allInSalabamSliders.length == 0) return
                    setTimeout(function(){
                        _this.getInsalabamPreview(_this.allInSalabamSliders[0])
                    }, 500)
                })
                
            },
        }
    }
</script>

<style lang="scss">
    
    
    

    .inSalabamMonographicBlock
    {

    }

</style>