<template>
    <div class="postSlider" v-if="!loading && posts.length > 0">
        <p class="sliderHeadingBordered">
            <span class="common-title">
            In|{{site.brand}}
            <span v-if="category.label != ''"> - {{category.label}}</span>
            </span>
            <br/>
            <span>In cerca di ispirazione e idee? Ti aiutiamo noi con le nostre selezioni. Clicca e scopri!</span>
        </p>
        <div class="slick">
            <Slick :ref="'slick_'+_uid" :options="slickOptions" @init="handleInit">
                <div v-for="(singlePost,index) in (posts.filter(function(inSalabam) { return !skip.includes(parseInt(inSalabam.inSalabamId)) && !excludeCategoryCodes.includes(inSalabam.category.code) }))" :key="'slide-insalabam-'+index">
                    <div class="postPad" :data-inSalabamId="singlePost.inSalabamId">
                    <div
                        class="postPad__thumbnail"
                        :style="'background-image:url('+singlePost.padImage+');'"
                    >
                        <span class="slickCredits" v-if="singlePost.credits !== undefined && singlePost.credits !== ''">{{singlePost.credits}}</span>
                    </div>

                    <div class="postPad__content">
                        <div>
                        <div class="postPad__title">{{singlePost.title}}</div>
                        <p class="postDate">{{singlePost.readableDate}}</p>
                        <div class="postPad__excerpt" v-html="singlePost.abstract"></div>
                        </div>
                        <div class="postPad__link">
                        <a @click="inSalabam(singlePost)">Scopri di più</a>
                        </div>
                    </div>
                    </div>
                </div>
            </Slick>
            <span class="slick-arrow" id="slick-prev-inSalabam" :prev="_uid">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="slick-arrow" id="slick-next-inSalabam" :next="_uid">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </div>
    </div>
    <div class="postSlider loading" v-else-if="loading">
        <Spinner />
    </div>
</template>

<script>
import Api from '../../api'
import Spinner from '../atoms/spinner'
import Slick from 'vue-slick';
//import Vue from "vue";

export default {
    name: 'in-salabam-slider',
    components:
    {
        Spinner,
        Slick,
    },
    props:
    {
        skip: {
            type: Array,
            default: () => new Array()
        },
        category: {
            type: Object,
            default: () => { return {code: '', label: ''} },
        },
        excludeCategoryCodes: {
            type: Array,
            default: () => new Array()
        },
    },
    data() {
        return {
            loading: true,
            posts: [],
            slickOptions: {
            autoplay: (this.$config.guiSettings.autoplaySpeedInSalabam != 0),
            autoplaySpeed: this.$config.guiSettings.autoplaySpeedInSalabam,
            //vertical: true,
            //verticalSwiping: true,
            slidesToShow: 3,
            prevArrow: '[prev='+this._uid+']',
            nextArrow: '[next='+this._uid+']',
            adaptiveHeight:true,
            infinite: true,
            responsive: [
                {
                breakpoint: 1600,
                settings: {
                    //adaptiveHeight:false,
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    //variableWidth: true,
                    //vertical: false,
                    //verticalSwiping: false,
                }
                },
                {
                breakpoint: 992,
                settings: {
                    //adaptiveHeight:false,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    //variableWidth: true,
                    //vertical: false,
                    //verticalSwiping: false,
                }
                }
            ]
            },
            site: this.$config.site,
        }
    },
    mounted()
    {
        let _this = this
        _this.loading = true

        Api.getInSalabam({
            categoryCode: this.category.code,
        })
        .then((data) => {
            if(data.results) _this.posts = data.results
            _this.loading = false
        })
    },
    methods:{
        handleInit(event, slick) {
            const [ slickTrack ] = slick.$slideTrack
            slickTrack.childNodes.forEach(slickSlide => {
                let _this = this
                slickSlide.onclick = function(e){
                    let inSalabamId = parseInt(e.target.closest('.postPad').getAttribute('data-inSalabamId'))
                    let inSalabam = _this.posts.filter(function (post) {
                        return post.inSalabamId == inSalabamId
                    })[0]
                    _this.inSalabam(inSalabam)
                }
            })
        },
        inSalabam(inSalabam)
        {

            if(this.$gtm) this.$gtm.trackEvent({
            event: 'customEvent',
            category: 'inSalabam',
            action: 'sliderClick',
            label: inSalabam.name,
            value: inSalabam.category.code,
            });

            this.mixinSendMutation('setGeo',inSalabam.geo)
            this.mixinGoTo('in-' + this.site.brand.toLowerCase(), {
            inSalabamId: inSalabam.inSalabamId,
            inSalabamTitle: (inSalabam.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
            setGeo: true,
            })

        }
    }
}
</script>

<style lang="scss">
/*
    
    
    
*/
</style>