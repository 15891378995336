<template>
    <div class="in-salabam-buttons" v-if="!loading && posts.length > 0">
        <div class="in-salabam-buttons-wrapper">
            <div class="in-salabam-super" v-if="superIn">
                <b-button variant="primary" :class="highlight.includes(parseInt(superIn.inSalabamId)) ? 'highlight' : ''" @click="clickSuperIn(superIn)">{{ superIn.name }}</b-button>
            </div>
            <div class="in-salabam-slider">
                <Slick ref="slick-in-salabam-buttons" :options="slickOptions">
                    <div class="slick-item" v-for="(singlePost,index) in posts.filter(function(p){ return !p.super && highlight.includes(parseInt(p.inSalabamId)) })" v-bind:key="_uid+'_highlight_'+index">
                        <b-button variant="primary" @click="clickNormale(singlePost)" class="highlight">
                            {{singlePost.name.substr(singlePost.name.indexOf(' '))}}
                        </b-button>
                    </div>
                    <div class="slick-item" v-for="(singlePost,index) in posts.filter(function(p){ return !p.super && !highlight.includes(parseInt(p.inSalabamId)) })" v-bind:key="_uid+'_'+index">
                        <b-button variant="primary" @click="clickNormale(singlePost)">
                            {{singlePost.name.substr(singlePost.name.indexOf(' '))}}
                        </b-button>
                    </div>
                </Slick>
                <span class="slick-arrow" id="slick-prev-button" :prev="_uid">
                    <ChevronDownToggler />
                </span>
                <span class="slick-arrow" id="slick-next-button" :next="_uid">
                    <ChevronDownToggler />
                </span>
            </div>
        </div>
    </div>
    <div class="in-salabam-buttons loading" v-else-if="loading">
        <Spinner />
    </div>
</template>

<script>
import Api from '../../api'
import Slick from 'vue-slick';
import Spinner from '../atoms/spinner'
import ChevronDownToggler from '../svg/chevron-down-toggler'

export default {
  name: 'post-button-slider',
  components: {
    Slick,
    Spinner,
    ChevronDownToggler,
  },
  props:
      {
        category: {
          type: Object,
          default: () => { return {code: '', label: ''} },
        },
        highlight: {
          type: Array,
          default: () => new Array()
        },
      },
  data() {
    return {
      loading: true,
      posts: [],
      superIn: {},
      site: this.$config.site,
      slickOptions: {
        autoplay: false,
        //autoplaySpeed: this.$config.guiSettings.autoplaySpeedPosts,
        draggable:false,
        variableWidth: true,
        prevArrow: '[prev='+this._uid+']',
        nextArrow: '[next='+this._uid+']',
        infinite: false,
        //adaptiveHeight:true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              //variableWidth: false,
              ///centerMode: false,
            }
          }
        ]
      },      
    }
  },
    mounted()
    {
        let _this = this
        _this.loading = true


        if(this.category.code == ''){
          _this.loading = false
          return
        }

        Api.getInSalabam({
            categoryCode: this.category.code,
        })
        .then((data) => {
            if(data.results){
                //_this.superIn = (data.results).filter(function(p){ return _this.highlight.includes(parseInt(p.inSalabamId)) })[0]
                _this.superIn = (data.results).filter(function(p){ return p.super })[0]                
                let inSalabams = (data.results).filter(function(inSalabam)
                {
                    //return !_this.highlight.includes(parseInt(inSalabam.inSalabamId))
                    return !_this.highlight.includes(parseInt(inSalabam.super))
                })
                _this.posts = inSalabams
            }
            else if(data.inSalabam)
            {
                _this.posts = [data.inSalabam]//inSalabams
            }
            else
            {
                //_this.posts = inSalabams
            }
            _this.loading = false
        })
    },
  methods:{
    clickSuperIn(inSalabam){

      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent',
        category: 'inSalabam',
        action: 'buttonsSliderClickSuper',
        label: inSalabam.category.code,
        value: inSalabam.name,
      });

      if(window.clarity) window.clarity("set", "inSalabam", "buttonsSliderClickSuper")

      this.inSalabam(inSalabam);


    },
    clickNormale(inSalabam){

      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent',
        category: 'inSalabam',
        action: 'buttonsSliderClick',
        label: inSalabam.category.code,
        value: inSalabam.name,
      });

      if(window.clarity) window.clarity("set", "inSalabam", "buttonsSliderClick")

      this.inSalabam(inSalabam);

    },
    inSalabam(inSalabam)
    {
      this.mixinSendMutation('setGeo',inSalabam.geo)
      this.mixinGoTo('in-' + this.site.brand.toLowerCase(), {
        inSalabamId: inSalabam.inSalabamId,
        inSalabamTitle: (inSalabam.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
        setGeo: true,
      })
    },
  }
}
</script>

<style lang="scss">
    
    
    
    

    .in-salabam-buttons
    {
        width:100%;
        position:relative;

        @include media-breakpoint-down(md)
        {
            max-width:400px;
        }
        .slick-item
        {
        }
        .btn
        {
            margin:6px 3px;
            white-space: nowrap;
            border:0;
            color:$white !important;
            padding-left:10px;
            padding-right:10px;
            //opacity:0.8;
            //@include theme-gradient-horizontal;
            @include customize-background-color("primary");
            @include media-breakpoint-up(lg)
            {
                padding-left:20px;
                padding-right:20px;
            }
            &:hover
            {
                //opacity:1;
            }
        }
        .slick-arrow
        {
            position:absolute;
            top:50%;
            border-radius: 50%;
            cursor: pointer;
            background: transparent;
            svg
            {
                width:60px;
                height:60px;
                path:last-child
                {
                    stroke:transparent;
                }
            }
            &#slick-prev-button
            {
                left:0;
                transform:translateY(-50%) scale(0.5) rotate(90deg);
            }
            &#slick-next-button
            {
                right:0;
                transform:translateY(-50%) scale(0.5) rotate(-90deg);
            }
        }

        .in-salabam-buttons-wrapper-content
        {

        }

        .in-salabam-buttons-wrapper
        {
            display:flex;
            flex-wrap: wrap;
            align-items:center;
            justify-content: space-between;
            .in-salabam-super
            {
                margin:auto;
                .btn
                {
                    font-weight: 500;
                    @include media-breakpoint-up(md)
                    {
                        padding-left: 45px;
                        padding-right: 45px;
                    }
                }
            }            
            .in-salabam-slider
            {
                position:relative;
                padding:0 60px;
                width:100%;
                .slick-item
                {
                    text-align: center;
                }
            }
            .in-salabam-super .btn
            {
                transition:$transition-base;
                &:not(.highlight)
                {
                    background: rgba(157, 157, 156, 0.8);// !important;
                }
                &.highlight,
                &:not(.highlight):hover
                {
                    @include customize-background-color("primary");
                    @extend [theme-gradient-horizontal] !optional;
                }
            }
            .in-salabam-slider .btn
            {
                transition:$transition-base;
                padding-top:8px;
                padding-bottom:8px;               
                background: rgba(157, 157, 156, 0.8);
                &:hover,
                &.highlight
                {
                    @include customize-background-color("primary");
                    @extend [theme-gradient-horizontal] !optional;
                }
            }
            @include media-breakpoint-up(lg)
            {
                flex-wrap: nowrap;
                margin-bottom:16px;
                .in-salabam-super
                {
                    .btn
                    {
                        white-space:initial;
                    }
                    max-width:300px;
                    overflow:hidden;
                    margin:0;
                }            
                .in-salabam-slider
                {
                    max-width:calc(100% - 300px);
                }
            }
            @include media-breakpoint-up(xl)
            {
                .in-salabam-super
                {
                    max-width:400px;
                }            
                .in-salabam-slider
                {
                    max-width:calc(100% - 400px);
                }
            }
        }
    }
</style>