<template>
  <div id="home" :class="'inVadobay ' + hideAutocomplete">
    
    <Spinner v-if="loading" />

    <div v-else v-bind:key="Object.keys(inSalabam).length">

      <PinnedMessage/>

      <Spinner v-if="Object.keys(inSalabam).length == 0" />
      <div id="hero" v-else>
        <Slick ref="slick-reviews" :options="slickOptions">
          <div class="hero__slide" v-lazy:background-image="inSalabam.backgroundImage">
              <div class="slickCredits" v-if="inSalabam.credits && inSalabam.credits !== ''" v-html="inSalabam.credits"></div>
            <div class="hero__slide-content">
              <div class="hero__slide-wrapper" :class="mixinIsAuthenticated() ? 'private' : 'public'">
                <h3 class="hero__slide-title">{{inSalabam.title}}</h3>
                <p class="hero__slide-text">{{inSalabam.abstract}}</p>
              </div>
            </div>
          </div>
        </Slick>
        <div class="hero__search-form">
            <InSalabamButtonsSlider
                v-if="inSalabam.category" :key="inSalabam.inSalabamId"
                :category="inSalabam.category"
                :highlight="[parseInt(inSalabam.inSalabamId)]"
            />
            <SearchForm v-on:submit-search="submitSearch" :submitCallback="submitCallback" />
        </div>
      </div>

      <b-container v-if="mixinIsAuthenticated() && $config.guiSettings.showInSalabam">
        <InSalabamSlider
            :skip="[parseInt(inSalabam.inSalabamId)]" :key="'inSalabamSlider'+inSalabam.inSalabamId"
        />
      </b-container>

      <b-container v-if="mixinIsAuthenticated() && $config.guiSettings.showInSalabam">
          <keep-alive>
            <InSalabamMonographic
                :limit="3"
                :skip="[parseInt(this.inSalabamId)]"
                :skipCategory="inSalabam.category"
            />
          </keep-alive>
      </b-container>

      <!--<b-container fluid v-if="mixinIsAuthenticated()">
        <SliderPropertyCategories />
      </b-container>-->

      <b-container fluid v-if="mixinUseTicketCompliments() && !mixinIsAuthenticated()">
        <b-row>
          <b-col sm="12">
            <p class="text-center mt-4 mb-4 pb-4">
              <router-link :to="{name: 'how-etc-works', hash:'#etc'}">
                <SvgEdenredButtonBlue />
              </router-link>
              <br>
              <br>
              Prenota un viaggio con Ticket Compliments
            </p>
          </b-col>
        </b-row>
      </b-container>


      <b-container v-if="$config.posts.category != 0">
        <PostSlider :category="$config.posts.category" />
      </b-container>

      <b-modal
        :id="'modal-home-public'"
        size='xl'
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
      >
        <Login />
      </b-modal>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Api from '../../api'
  import SvgEdenredButtonBlue from '../svg/edenred-button-blue'
  import Slick from 'vue-slick'
  import SearchForm from '../blocks/search-form/search-form'
  //import SliderPropertyCategories from '../blocks/slider-property-categories'
  import PinnedMessage from '../blocks/common/pinnedMessage'
  import InSalabamSlider from '../blocks/in-salabam-slider'
  import PostSlider from '../blocks/post-slider'
  import Login from '../blocks/login'
  import Spinner from '../atoms/spinner'
  import InSalabamMonographic from '../blocks/in-salabam-monographic/in-salabam-monographic'
  import InSalabamButtonsSlider from '../blocks/in-salabam-buttons-slider'

  export default {
    name: 'in-vadobay',
    metaInfo() {
      return {
        title: process.env.VUE_APP_SITE_PAYOFF,
        meta: [

          { vmid: 'metaDescription', name: 'Description', content: this.inSalabam.abstract + ' ' + 'in|' + this.site.brand + ' ' + this.inSalabam.title },

          { vmid: 'ogTitle', property: 'og:title', content: 'in|' + this.site.brand + ' ' + this.inSalabam.title },

          { vmid: 'ogDescription', name: 'og:description', content: this.inSalabam.abstract },

          { vmid: 'ogType', name: 'og:type', content: 'website' },

          { vmid: 'ogUrl', name: 'og:url', content: process.env.VUE_APP_SITE_HOST.slice(0, -1) + window.location.pathname},

          { vmid: 'ogImage', name: 'og:image', content: this.inSalabam.backgroundImage},

          //{ vmid: 'ogImageWidth', name: 'og:image:width', content: undefined },

          //{ vmid: 'ogImageHeight', name: 'og:image:height', content: undefined },

        ]
      }
    }, // metaInfo
    props:
    {
      //inSalabam: Object
      inSalabamId: String,
      setGeo: {
        type: Boolean,
        default: false,
      },
      inSalabamTitle: 
      {
        type: String,
        default: ''
      },
    },
    components: {
      SvgEdenredButtonBlue,
      Slick,
      SearchForm,
      //SliderPropertyCategories,
      PinnedMessage,
      InSalabamSlider,
      PostSlider,
      Login,
      Spinner,
      InSalabamMonographic,
      InSalabamButtonsSlider,
    },
    data() {
        return {
          loading: true,
          inSalabam: {},
          slides: this.mixinIsAuthenticated() ? Object.values(Vue.prototype.$config.sliderHome.private) :  Object.values(Vue.prototype.$config.sliderHome.public),
          slickOptions: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots:true,
              fade:true,
              arrows:false,
              autoplay:true,
              speed: 2500,
              autoplaySpeed: 7500,
              responsive: [{
                /*
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
                */
              }]
          },
          submitCallback: function()
          {
            //this.mixinGoTo('search',{})
          },
          site: this.$config.site,
        }
    },
    watch: {
      inSalabamId: function() {
        // inSalabamId cambia quando viene cliccata una pad nello slider inSalabam
        // così recupero i nuovi dati e via
        // TODO sarebbe da gestire sia il caso prop che il caso id
        // inSalabamId viene passato al caricamento iniziale se guiSettings.loadHome_inSalabam
        // quindi non conosco ancora i dati degli inSalabam e devo recuperare questo specifico tramite api
        // se invece clicco lo slider, potrei passare tutto come prop (e a questo punto il watch non dovrebbe più servire)
        this.loadCustomization()
      }
    },

    created()
    {
    },

    mounted() {

      //this.loading = true

      // resetto filtri e categorie eventualmente settati nello state tramite local storage
      this.mixinSendMutation('setAmenities',[])
      this.mixinSendMutation('setCategories',[])
      if(!this.mixinIsAuthenticated())
      {
        // mostro la modal solo ogni volta ogni x minuti
        if(
             !this.lastPublicHomeModalViewTs
          || (Math.floor(Date.now() / 1000)) - this.lastPublicHomeModalViewTs > 60*5 // 5 minuti
        ){
          this.lastPublicHomeModalViewTs = Math.floor(Date.now() / 1000)
          this.$bvModal.show('modal-home-public')
        }
      }

      this.loadCustomization()

    },
    methods:{
      loadCustomization()
      {
        let _this = this
        Api
            .getInSalabam({inSalabamId: this.inSalabamId})
                .then((data) => {
                    
                    // per rimandare alla home specifica solo al primo accesso
                    if( this.$config.guiSettings.loadHome_inSalabamId )
                    {
                        delete(this.$config.guiSettings.loadHome_inSalabamId)   
                    }


                    if(!data.inSalabam){
                        // bodyguard se viene passato un inSalabam che non esiste più
                        if(process.env.VUE_APP_MODE != 'production') window.console.log('inSalabam non trovato, torno alla home');

                        if( this.$config.guiSettings.loadHome_inSalabamId )
                        {
                          // altrimenti la home riprova a mandare qui
                          delete(this.$config.guiSettings.loadHome_inSalabamId)
                        }

                        if( this.$config.guiSettings.loadHome_inSalabamId_asDb )
                        {
                          // altrimenti la home riprova a mandare qui
                          delete(this.$config.guiSettings.loadHome_inSalabamId_asDb)
                        }

                        this.mixinGoTo('homepage',{})
                        return
                    }

                    _this.inSalabam = data.inSalabam
                    _this.loading = false

                    if(data.inSalabam.geo && _this.setGeo) this.mixinSendMutation('setGeo', data.inSalabam.geo)

                    if(this.$gtm) this.$gtm.trackEvent({
                      event: 'customEvent',
                      category: 'inSalabam',
                      action: 'homeLoad',
                      label: data.inSalabam.category.code,
                      value: data.inSalabam.name,
                    });

                    if(window.clarity) window.clarity("set", "inSalabam", "homeLoad")
            })
      },
      submitSearch()
      {
        this.mixinSendMutation('setTriggerSearch',true)
        this.mixinGoTo('search',{})
      }
    },
    computed: {
      lastPublicHomeModalViewTs: {
        get() { return this.$store.state.lastPublicHomeModalViewTs },
        set(value) { this.mixinSendMutation('setlastPublicHomeModalViewTs',value) }
      },
      hideAutocomplete: {
        get(){
          // se viene impostata automaticamente la ricerca (sullo specifico inSalabam)
          // nascondo l'autocomplete
          if(this.setGeo) return 'hideAutocomplete'
          return ''
        },
        set(){},
      },
    }
  }
</script>

<style lang="scss">
  
    .inVadobay
    {
        &#home .pinnedMessage .alert
        {
            margin-bottom: 0px; /* coronavirus */
        }

        &#home.hideAutocomplete
        {
            .search-form-autocomplete
            {
                display:none !important;
            }
        }

        .hero__search-form
        {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            margin-top:-50px !important;
            
            .in-salabam-buttons
            {
                display:block;
                width:100%;
            }

            /* bordi solo su homepage */
            .search-form-item #dropdownOccupancy{
              border: 1px solid;
              @include customize-border-color("primary");
            }

            #search-form{
              input, dropdown{
                @include customize-border-color("primary");
              }
            }

        }

        .hero__slide-title
        {
            @include customize-color(text, important);
        }

        .hero__slide-text
        {
            @include customize-color(text, important);
        }

        #hero .hero__slide
        {
            &-wrapper
            {
                max-width: 700px;
            }
            @include media-breakpoint-down(md)
            {
                &-content
                {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    margin: 0 auto;
                }
                &-wrapper
                {
                    display:block;
                    position: absolute;
                    right: 0;     
                    padding: 1rem 1rem 1.25rem 1rem;
                    width: 95vw;        
                    top: auto;
                    bottom: 5rem;
                    transform: none;
                }
                &-title
                {
                    font-size: 20px;
                }
                &-text
                {
                    font-size: 11px;
                    line-height: 120%;
                }
            }
        }
    }
</style>