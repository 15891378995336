<template>
    <div class="fascia-welfare">
        <b-row>
            <b-col sm="12" md="6">
                <div class="text clearfix">
                    <h4 class="black">Viaggia col welfare aziendale!</h4>
                    
                    <br>

                    <p>
                      {{ site.brand }} è in tutti i principali portali di welfare aziendale. 
                      Per prenotare un viaggio con il tuo credito welfare devi accedere a {{ site.brand }} partendo dal link che trovi dentro il tuo portale di welfare aziendale.
                    </p>
                    <router-link class="float-right" :to="{name: 'how-it-works', hash:'#come-funziona'}"><u>Scopri di più...</u></router-link>
                </div>
            </b-col>
            <b-col class="image" sm="12" md="6" style="background-image:url(../../assets/fascia-welfare.jpg) "></b-col>
        </b-row>
    </div>
</template>

<script>
//import FormRichiestaAccesso from '@/components/blocks/common/form-richiesta-accesso'

export default {
    name: 'fascia-welfare',
    props: {
    }, 
    components: {
    },
    data() {
        return {
          site: this.$config.site,
        }
    }
}
</script>

<style lang="scss">
    
    .fascia-welfare
    {
        margin: $grid-gutter-width/2;
        .row
        {
            background:$white;
            display:flex;
            align-items: center;
            overflow:hidden;
            border-radius: $border-radius-lg;
        }
        .col-sm-12
        {
        }
        .text
        {
            padding:$grid-gutter-width/2 $grid-gutter-width/4;
        }
        .image
        {
            min-height: 300px;
            background-position: 0 0;
            background-size: cover;
            background-repeat: no-repeat;

            @include media-breakpoint-down(lg)
            {
                background-position: -100px center;
            }

            @include media-breakpoint-down(md)
            {
                background-position: -180px center;
            }

            @include media-breakpoint-down(sm)
            {
                background-position: center center;
            }
        }

        @include media-breakpoint-up(md)
        {
            margin-top:$grid-gutter-width*2;
            margin-bottom:$grid-gutter-width*2;
            .row
            {
                display: table;
            }
            .col-sm-12
            {
                display: table-cell;
            }
            .text
            {
                padding:$grid-gutter-width $grid-gutter-width/2;
            }
        }
    }
</style>