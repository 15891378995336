<template>
    <div v-if="isAmbalagi && !mixinIsWelfare()" class="paymentsList">
        <div class="vivamod_loyalty">
            <!--<a class="link" :href="businessVivamodHost" target="_blank">-->
            <a class="link" href="https://vacanze.vivamod.com/" target="_blank">
              <CustomerCareAgent class="vivamod_loyalty_icon" />
              <span>agenzia viaggi by Vivamod. Scopri...</span>
            </a>
        </div>
        <div class="logos">
            <span class="mr-2">Paghi con:</span>
            <span class="logo">
                <img src="assets/pagamenti/visa.svg" />
            </span>
            <span class="logo">
                <img src="assets/pagamenti/mastercard.svg" />
            </span>
            <span class="logo">
                <img src="assets/pagamenti/american-express.svg" />
            </span>
            <span class="logo">
                <SvgLogoDiscountCode class="logo" />
            </span>
            <span class="logo" v-if="config.guiSettings.integrations.etc.enabled">
                <img src="assets/pagamenti/etc.svg" />
            </span>
            <span class="logo" v-if="config.guiSettings.integrations.promoshopping.enabled">
                <img src="assets/pagamenti/promoshopping.svg" />
            </span>
            <span class="logo" v-if="config.guiSettings.integrations.scalapay.enabled">
                <img src="assets/pagamenti/scalapay.svg" />
            </span>
            <a class="logo ml-3" @click="mixinGoTo('sistemi-di-pagamento',{})">Scopri di più</a>
        </div>
        <!-- amex | mastecard | visa | promoshopping | scalapay | ticket compliments -->

        <!--<div class="vivamod_adv">
            <img src="assets/logo-agenzia-vivamod.svg" />
            <router-link :to="{name: 'adv'}">
                <strong>Sei utente welfare aziendale?</strong><br/>
                <span>Scopri come utilizzare il tuo credito con Agenzia viaggi by Vivamod</span>
            </router-link>
        </div>-->
    </div>
</template>

<script>

import Vue from "vue"
import CustomerCareAgent from '../svg/customer-care-agent.vue'

export default {
  name: 'payments-list',
  components: {
    SvgLogoDiscountCode: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/logoDiscountCode')
      .then(function(SvgLogoDiscountCode) {
        window.console.log('async: SvgLogoDiscountCode Component loaded')
        return SvgLogoDiscountCode
      }),
    CustomerCareAgent,
  },
  data(){
    return {
      config: Vue.prototype.$config,
      isAmbalagi: process.env.VUE_APP_NAME == 'ambalagi',
      businessVivamodHost: process.env.VUE_APP_BUSINESS_VIVAMOD_HOST,
    }
  }
}
</script>

<style lang="scss">
    .paymentsList
    {
        padding-top: 20px;
        display:flex;
        flex-wrap:wrap;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color:$black;
        @include customize-color(text);

        & > div {
            padding-right: 20px;
            margin-right: 20px;

            margin-top: 15px;
            margin-bottom: 15px;

            @include media-breakpoint-up(xxl){
                border-right: 1px solid $gray-500;
            }

            &:last-child {
                border-right: 0;
                padding-right: 0;
                margin-right: 0;
            }
        }

        .vivamod_adv {
            display: flex;
            align-items: center;

            a {
                color: $tertiary;
            }

            img {
                width: 40px;
                margin-right: 10px;
            }

            span {
                font-size: 11px; 
                display: block;
            }
        }

        .vivamod_loyalty {
          &_icon path {
            fill: $primary;
          }
        }

        .link
        {
            @include customize-color(cta);
            display: block;
            span
            {
                text-decoration: underline;
            }
            svg
            {
                width: 26px;
                height: auto;
                margin-right: 10px;
            }
        }

        .logos
        {
            display:flex;
            align-items: center;
            flex-wrap:wrap;
            justify-content: center;
            max-width: 500px;

            .mr-2
            {
                min-width: 100%;
                text-align: center;
            }
            @include media-breakpoint-up(lg)
            {
                //justify-content: flex-start;
                .mr-2
                {
                    min-width: auto;
                    text-align: right;
                    padding-bottom:0;
                }
            }
            @include media-breakpoint-up(xl)
            {
                max-width: 100%;
            }
        }

        .logo
        {
            @extend .custom-color-cta !optional;
            text-decoration: underline;
            &:hover
            {
                text-decoration: underline;
            }
            img,svg
            {
                height:18px;
                width:auto;
                margin:8px;
            }
        }
    }
</style>