<template>
  <div class="form">

    <br/>

    <b-row v-if="apiResponse == ''">
      <b-col sm="12">
        <div class="loginRight">
          <div class="loginRightRow">
            <div class="icon accessIcon">
              <IconAccessKey />
            </div>
            <div class="title">
                <span  v-if="formTitle != ''" class="formTitle"><strong>{{formTitle}}</strong></span>
                <br/>
                <span v-if="formSubTitle != ''" class="formSubTitle">{{formSubTitle}}</span>
                <br/><br/>

                <b-form-group :state="validateFields()">

                <b-row>
                    <b-col sm="12" md="12">
                    <div class="customInputBlock">
                        <b-form-input ref="firstName" :required="true" v-model="firstName" placeholder="nome" data-e2e="salabamInput"/>
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('firstName')"></span>
                    </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="12">
                    <div class="customInputBlock">
                        <b-form-input ref="lastName" :required="true" v-model="lastName" placeholder="cognome" data-e2e="salabamInput"/>
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('lastName')"></span>
                    </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="12">
                    <div class="customInputBlock">
                        <b-form-input ref="azienda" :required="true" v-model="azienda" placeholder="azienda" data-e2e="salabamInput"/>
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('azienda')"></span>
                    </div>
                    </b-col>
                </b-row>


                <b-row>
                    <b-col sm="12">
                    <div class="customInputBlock">
                        <b-form-input ref="email" :required="true" v-model="email" type="text" placeholder="email" data-e2e="salabamInput"/>
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('email')"></span>
                    </div>
                    </b-col>

                    <b-col sm="12">
                    <div class="customInputBlock">
                        <b-form-input ref="emailConfirm" :required="true" v-model="emailConfirm" type="text" placeholder="conferma email" data-e2e="salabamInput"/>
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('emailConfirm')"></span>
                    </div>
                    </b-col>
                </b-row>

                <br/>

                <b-row>
                    <b-col sm="12">
                    <div class="customCheckboxBlock">
                        <b-form-checkbox v-model="privacy" ref="privacy" type="checkbox" name="privacy" :id="_uid+'-privacy'" class="privacyCheckbox"/>
                        <label :for="_uid+'-privacy'">
                            <a :href="privacyPolicyUrl" target="_blank" class="secondary">Ho letto ed accetto la Privacy Policy</a>
                        </label>
                    </div>
                    </b-col>
                </b-row>

                </b-form-group>

            </div>
            <div class="dots"></div>
            <div class="button">
              
                    <div v-if="loading">
                        <Spinner />
                    </div>
                    <div v-else>
                    
                    <div v-if="apiResponse !== ''">
                        <!--
                        <b-alert variant="info" show>
                        {{apiResponse}}
                        </b-alert>
                        <br>
                        -->
                    </div>

                    <div v-else>
                        <b-button :disabled="!validateFields()" @click="submitForm">Richiedi</b-button>
                        <br>
                        <p class="text-center">
                            Oppure invia un'email a <a :href="'mailto:' + recipient">{{recipient}}</a>
                        </p>
                    </div>

                </div>

            </div>
          </div>
        </div>
      </b-col>

      
    </b-row>

    <b-row v-else>
        <b-col lg="10" xl="8">
            
            <br><br>
            <span class="d-inline">
            <b-alert variant="info" show>
                {{apiResponse}}
            </b-alert>
            </span>

            <br>

            <p>
                <strong>Grazie della tua richiesta! Riceverai le tue credenziali per email a strettissimo giro.</strong>
            </p>

            <p>
                Potrai così controllare in tempo reale tutte le disponibilità di {{config.site.brand}} scegliendo online tra oltre {{config.guiSettings.totalPropertiesCount}} hotel, B&B, appartamenti in Italia e nel mondo.
            </p>

            <p>
                Usa la webchat o l'indirizzo <a :href="'mailto:' + recipient">{{recipient}}</a> per qualsiasi ulteriore informazione.
            </p>

        </b-col>
    </b-row>

  </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Spinner from '../../atoms/spinner'
import IconAccessKey from '@/components/svg/access-key'

export default {
  name: 'form-segnalazione-generica',
  props:
      {
        formTitle:
            {
              type: String,
              default: '', // titolo del form, renderizzato in alto se != ''
            },
        formSubTitle:
            {
              type: String,
              default: '', // sottotitolo del form, renderizzato in alto se != ''
            },
        type:
            {
              type: String,
              default: 'form-segnalazione-generica', // tipo form, usato per distinguere i form e per tracking
            },
        label:
            {
              type: String,
              default: '', // per distinguere lo stesso form messo in posti diversi, usato anche per tracking
            },
        recipient:
            {
              type: String,
              default: 'assistenza@vadobay.com', // email destinatario
            }
      },
  components:
      {
        IconAccessKey,
        Spinner,
      },
  data()
  {
    return {
        config: Vue.prototype.$config,
        recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
        privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
        uid: this._uid,
        loading: false,
        recaptchaToken: '',
        firstName: '',
        lastName: '',
        azienda: '',
        email: '',
        emailConfirm: '',
        privacy: false,
        apiResponse: '',
    }
  },
  mounted()
  {
    if(Vue.prototype.$config.recaptcha.enabled)
    {
      Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })
    }
  },
  methods:
      {
        async recaptcha() {
          await this.$recaptchaLoaded()
          const token = await this.$recaptcha('login')
          this.recaptchaToken = token
          this.sendToApi()
        },
        clearField(ref)
        {
          this[ref] = ''
          this.$refs[ref].focus()
          this.validateFields()
        },
        submitForm()
        {
          this.loading = true
          if(this.recaptchaEnabled)
          {
            this.recaptcha()
          }
          else
          {
            this.sendToApi()
          }
        },
        sendToApi()
        {
          let _this = this

          Api.formsSubmitter({
            recaptcha: _this.recaptchaToken,
            fields: {
              firstName: _this.firstName,
              lastName: _this.lastName,
              azienda: _this.azienda,
              email: _this.email,
            },
            settings:
                {
                  type: _this.type,
                  label: _this.label,
                  recipient: _this.recipient,
                  formTitle: _this.formTitle,
                  formSubTitle: _this.formSubTitle,
                }
          })
              .then((data) => {

                if(process.env.VUE_APP_MODE != 'production') window.console.log(data)
                if(process.env.VUE_APP_MODE != 'production') window.console.log(data.msg)

                _this.apiResponse = (data.msg)? data.msg : 'ok'
                _this.loading = false

                if(_this.$gtm) _this.$gtm.trackEvent({
                  event: 'customEvent',
                  category: 'userBehaviour',
                  action: 'formSubmit',
                  label: _this.type,
                  value: _this.label,
                })

              })
        },
        validateFields()
        {
          if(Object.keys(this.$refs).length == 0) return false

          return this.mixinValidateFields([
            {
              ref: this.$refs.firstName,
              type: 'firstName',
              value: this.firstName,
              optional: false,
            },
            {
              ref: this.$refs.lastName,
              type: 'lastName',
              value: this.lastName,
              optional: false,
            },/*
            {
              ref: this.$refs.azienda,
              type: 'textarea',
              value: this.azienda,
              optional: false,
            },*/
            {
              ref: this.$refs.email,
              type: 'email',
              value: this.email,
              optional: false,
            },
            {
              ref: this.$refs.emailConfirm,
              type: 'emailConfirm',
              value: (this.email == this.emailConfirm),
              optional: false,
            },
            {
              ref: this.$refs.privacy,
              type: 'checkbox',
              value: this.privacy,
              optional: false,
            },
          ])
        },
      }
}
</script>

<style lang="scss">



.form
{
  .customInputBlock input:focus ~ .formClear
  {
    top:18px;
    transform:none;
  }

  input,
  select,
  textarea,
  .custom-select,
  .dropdown,
  .form-control
  .input-group
  {
    border:1px solid #efefff !important;
    margin-bottom:1rem;
  }

  .formTitle {

  }

  .privacyCheckbox {
    float: left;
  }
  .accessIcon
  {
      margin:0;
      padding:0;
      display: block !important;
      min-height: 100%;
      flex:1;
      svg
      {
          height:auto;
          width:32px;
            transform: none !important;
      }
  }
  .loginRightRow
  {
      align-items: unset !important;
      .button
      {
          display: flex;
          align-items: flex-end;
          justify-content: center;
      }
      p
      {
          margin-bottom:8px;
      }
  }

  .dots
  {
      visibility:hidden;
      //margin-bottom:80px !important;
  }
    

}
</style>