<template>
  <div id="home">

    <PinnedMessage/>

    <div id="hero">
      <Slick ref="slick-reviews" :options="slickOptions">
        <div v-for="(slide,index) in slides" v-bind:key="index" class="hero__slide" v-lazy:background-image="slide.wallpaper">
            <div class="slickCredits" v-if="slide.credits && slide.credits !== ''" v-html="slide.credits"></div>
          <div class="hero__slide-content">
            <div class="hero__slide-wrapper" :class="mixinIsAuthenticated() ? 'private' : 'public'">
              <h3 class="hero__slide-title" v-html="slide.title"></h3>
              <p class="hero__slide-text" v-html="slide.text"></p>
            </div>
          </div>
        </div>
      </Slick>
      <div class="hero__search-form">
        <SearchForm
          v-on:submit-search="submitSearch"
          v-on:filter-changed="filterChanged"
          :submitCallback="submitCallback"
        />
      </div>
    </div>

    <b-container v-if="mixinIsAuthenticated() && $config.guiSettings.showInSalabam">
        <keep-alive>
            <InSalabamMonographic />
        </keep-alive>
    </b-container>

    <b-container v-if="mixinIsAuthenticated() && $config.guiSettings.showInSalabam">
      <InSalabamSlider />
    </b-container>

    <!--<b-container fluid v-if="mixinIsAuthenticated()">
      <SliderPropertyCategories />
    </b-container>-->

    <b-container fluid v-if="mixinUseTicketCompliments() && !mixinIsAuthenticated()">
      <b-row>
        <b-col sm="12">
          <p class="text-center mt-4 mb-4 pb-4">
            <router-link :to="{name: 'how-etc-works', hash:'#etc'}">
              <SvgEdenredButtonBlue />
            </router-link>
            <br>
            <br>
            Prenta un viaggio con Ticket Compliments
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="$config.posts.category != 0">
      <PostSlider :category="$config.posts.category" />
    </b-container>

    <b-modal
      :id="'modal-home-public'"
      ref="modal-home-public"
      size='xl'
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <Login :isModal="true" v-on:close-modal="$root.$emit('bv::hide::modal', 'modal-home-public')" />
    </b-modal>
    

  </div>
</template>

<script>
  import Vue from 'vue'
  import SvgEdenredButtonBlue from '../svg/edenred-button-blue'
  import Slick from 'vue-slick'
  import SearchForm from '../blocks/search-form/search-form'
  // import SliderPropertyCategories from '../blocks/slider-property-categories'
  import PinnedMessage from '../blocks/common/pinnedMessage'
  import InSalabamSlider from '../blocks/in-salabam-slider'
  import InSalabamMonographic from '../blocks/in-salabam-monographic/in-salabam-monographic'
  import PostSlider from '../blocks/post-slider'
  import Login from '../blocks/login'

  export default {
    name: 'not-found',
    metaInfo(){
      return {
        title: 'not found',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: this.prerenderStatusCode }
        ]
      }
    },
    components: {
      SvgEdenredButtonBlue,
      Slick,
      SearchForm,
      //SliderPropertyCategories,
      PinnedMessage,
      InSalabamSlider,
      InSalabamMonographic,
      PostSlider,
      Login,
    },
    props: {
      prerenderStatusCode: {
        type: String,
        default: '200',
      }
    },
    data() {
        return {
          slides: this.mixinIsAuthenticated() ? Object.values(Vue.prototype.$config.sliderHome.private) :  Object.values(Vue.prototype.$config.sliderHome.public),
          slickOptions: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots:true,
              fade:true,
              arrows:false,
              autoplay:true,
              speed: 2500,
              autoplaySpeed: 7500,
              responsive: [{
                /*
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
                */
              }]
          },
          submitCallback: function()
          {
            //this.mixinGoTo('search',{})
          },
          site: this.$config.site,
        }
    },
    created()
    {

      // inSalabam normale
      if(this.$config.guiSettings.loadHome_inSalabamId && this.$config.guiSettings.loadHome_inSalabamId != 0 )
      {

        if(process.env.VUE_APP_MODE != 'production') window.console.log('mixinGoTo in-vadobay ' + this.$config.guiSettings.loadHome_inSalabamId)

        this.mixinGoTo('in-' + this.site.brand.toLowerCase(), {
          inSalabamId: (this.$config.guiSettings.loadHome_inSalabamId).toString(),
          inSalabamTitle: (this.$config.guiSettings.loadHome_inSalabamTitle)? this.$config.guiSettings.loadHome_inSalabamTitle : '',
          setGeo: true,
        })
      }


    },
    mounted() {
      // resetto filtri e categorie eventualmente settati nello state tramite local storage
      this.mixinSendMutation('setAmenities',[])
      this.mixinSendMutation('setCategories',[])
      if(!this.mixinIsAuthenticated())
      {

        // mostro la modal solo ogni volta ogni x minuti
        if(
             !this.lastPublicHomeModalViewTs
          || (Math.floor(Date.now() / 1000)) - this.lastPublicHomeModalViewTs > 60*5 // 5 minuti
        ){
          this.lastPublicHomeModalViewTs = Math.floor(Date.now() / 1000)
          this.$bvModal.show('modal-home-public')
        }


      }


      // inSalabam asDb
      // rimanda sempre alla home specifica
      if(this.$config.guiSettings.loadHome_inSalabamId_asDb && this.$config.guiSettings.loadHome_inSalabamId_asDb != 0 )
      {

        if(process.env.VUE_APP_MODE != 'production') window.console.log('mixinGoTo in-vadobay ' + this.$config.guiSettings.loadHome_inSalabamId_asDb)

        this.mixinGoTo('in-' + this.site.brand.toLowerCase(), {
          inSalabamId: (this.$config.guiSettings.loadHome_inSalabamId_asDb).toString(),
          inSalabamTitle: (this.$config.guiSettings.loadHome_inSalabamTitle)? this.$config.guiSettings.loadHome_inSalabamTitle : '-',
          setGeo: false,
        })
      }

    },
    methods:{
      filterChanged() {},
      submitSearch()
      {
        this.mixinSendMutation('setTriggerSearch',true)
        this.mixinGoTo('search',{})
      }
    },
    computed: {
      lastPublicHomeModalViewTs: {
        get() { return this.$store.state.lastPublicHomeModalViewTs },
        set(value) { this.mixinSendMutation('setlastPublicHomeModalViewTs',value) }
      },
    }
  }
</script>

<style lang="scss">
  
  #home .pinnedMessage .alert{
    margin-bottom: 0px; /* coronavirus */
  }

    .hero__slide-title
    {
        @include customize-color(text, important);
    }

    .hero__slide-text
    {
        @include customize-color(secondary, important);
    }

</style>