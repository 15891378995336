<template>
  <div class="loginBlock">
    <b-row>
      <b-col sm="12" lg="4">
        <div class="loginLeft">
          <Logo />
          <p>
            Vuoi prenotare un viaggio?
          </p>
        </div>
      </b-col>
      <b-col sm="12" lg="8">
        <div class="loginRight">
          <div class="loginRightRow">
            <div class="icon welfare">
              <font-awesome-icon icon="briefcase"/>
            </div>
            <div class="title">
              <strong>Usa il welfare aziendale!</strong>
              <br>
              Fai login sul tuo portale welfare, cerca {{ config.site.brand }} tra i fornitori di servizi e poi accedi a {{ config.site.brand }}
            </div>
            <div class="dots"></div>
            <div class="button">
              <router-link :to="{name: 'how-it-works', hash:'#come-funziona'}"><b-button>Scopri come!</b-button></router-link>
            </div>
          </div>
          <div class="loginRightRow">
            <div class="icon dc"><TicketIcon /></div>
            <div class="title">
              <strong>Usa un Buono Sconto</strong>
            </div>
            <div class="dots"></div>
            <div class="button">
              <router-link :to="{name: 'dc-sso'}"><b-button data-e2e="homeModalLoadDiscountCode">Caricalo qui!</b-button></router-link>
            </div>
          </div>
          <div class="loginRightRow" v-if="mixinUseTicketCompliments()">
            <div class="icon etc"><TicketIcon /></div>
            <div class="title">
              <strong>Usa Ticket Compliments</strong>  
            </div>
            <div class="dots"></div>
            <div class="button">
              <router-link :to="{name: 'how-etc-works', hash:'#etc'}">
                <!--<SvgEdenredLogoWhite />-->
                <img src="../../assets/logoEdenred.png" />
              </router-link>
            </div>
          </div>
          <!--<div class="loginRightRow">
            <div class="icon ps"><TicketIcon /></div>
            <div class="title">
              <strong>
                Usa Promo Shopping
              </strong>
            </div>
            <div class="dots"></div>
            <div class="button">
              <router-link :to="{name: 'dc-sso'}"><img src="../../assets/logoPromoshopping.png" /></router-link>
            </div>
          </div>-->


          <div class="loginRightRow">

            <FormRichiestaAccesso
                :formTitle="'Vuoi vedere le disponibilità live?'"
                :formSubTitle="'Richiedi l\'accesso'"
                :label="'modal-richiesta-accesso'"
                :recipient="config.site.emailSupport"
            />

          </div>


          <div class="closeModal" v-if="isModal" @click="$emit('close-modal')">
            <strong>Oppure vai alla homepage</strong>
          </div>          
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import TicketIcon from '../svg/ticket-icon'
//import SvgEdenredLogoWhite from '../svg/edenred-logo-white'
import Logo from '../svg/logo'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import FormRichiestaAccesso from '@/components/blocks/common/form-richiesta-accesso'

library.add(faBriefcase)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: 'login',
  props:
  {
    isModal: {
      type:Boolean,
      default:false,
    }
  }, 
  components: 
  {
    TicketIcon,
    Logo,
    //SvgEdenredLogoWhite,
    FormRichiestaAccesso,
  },
  data()
  {
      return {
          config: this.appConfig()
      }
  }
}
</script>

<style lang="scss">
  
  
  
  

  .loginBlock
  {
    padding:2rem;
    .closeModal
    {
      @include customize-color("secondary");
      cursor:pointer;
      text-align:right;
      text-decoration:underline;
      margin-top:0.5rem;
      margin-right:0.5rem;
      font-size: 14px;
    }
    .loginLeft
    {
      text-align:center;
      p
      {
        color:$white;
        background-color:#1E4D8B;
        font-size: 32px;
        line-height: 100%;
        padding:1.5rem 1.5rem;
        margin-top:1rem;
        font-weight: medium;
      }
    }
    .loginRightRow
    {
      display:flex;
      justify-content:space-between;
      align-items: flex-end;
      margin-bottom:1.25rem;
      min-height:60px;
    }

    .icon
    {
      min-width:60px;
      text-align:center;
      svg
      {
        transform:scale(1.75);
      }

      &.dc * { fill:#F9B14B; }
      &.etc * { fill:#171E54; }
      &.ps * { fill:#C2102B; }

      &.welfare
      {
        @include customize-color("secondary");
        font-size: 40px;
        svg
        {
          transform:none;
          width:60px;
        }
      }

    }

    font-size: 0.95rem;

    .title
    {
      text-align:left;
      //flex:1;
      p
      {
        margin-bottom:0;
      }
      padding:0 0 0 0.5rem;
      line-height: 1.35;
      strong
      {
        font-size: 1rem;
      }
    }
    .dots
    {
      flex:1;
      border-bottom:1px solid $medium-gray;
      min-width:40px;
      //padding-top:20px;
      margin:0 0.75rem 0.35rem 0.75rem;
    }
    .button
    {
      min-width:200px;
      max-width:200px;
      a,button,img,svg
      {
        display:block;
        width:100%;
        white-space: nowrap;
        &:hover
        {
          text-decoration: none;
        }
      }
    }

    @include media-breakpoint-down(md)
    {
      margin-bottom: 2rem;
      .loginRightRow
      {
        flex-direction:column;
        text-align: center;
        justify-content: center;
        align-items: center;
        *
        {
          text-align:center;
        }
        .dots {display:none;}
        .title
        {
          padding:0;
        }
        .icon
        {
          margin-bottom:0.5rem;
          &.dc,&.ps
          {
            margin-bottom:1rem;
          }
        }
        .button
        {
          margin-top:1rem;
          margin-bottom:1.5rem;
        }
      }
    }
  }
</style>