<template>
  <div class="inSalabamMonographicSlider">
    <Spinner v-if="loading" />
    <div v-else-if="inSalabam.data.results.length > 0" class="postSlider">

        <div class="postSlider" v-if="!loading">
            <p class="sliderHeading sliderHeadingBordered">
                <span class="common-title">{{inSalabam.infoPad.title}}</span>
                <br>
                <span class="d-flex align-items-center flex-wrap">
                    <!--<SvgCalendar />-->
                    Disponibilità verificate in tempo reale&nbsp;<span class="mobileHide">con IN: {{inSalabam.data.checkin}} - OUT: {{inSalabam.data.checkout}}&nbsp;-&nbsp;</span>
                    <a href="#"
                        class="mobileNewline"
                        @click="mixinGoTo('in-' + site.brand.toLowerCase(),{
                        inSalabamId: inSalabam.infoPad.inSalabamId,
                        inSalabamTitle: (inSalabam.infoPad.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
                        setGeo: true,
                    })"><b>Cerca nelle tue date!</b></a>
                </span>
            </p>

            <!-- versione mobile: pad informativa inserita tra le slides -->
            <div class="slick mobile">
                <Slick :ref="_uid+'_slick'" :options="slickOptionsMobile" @init="handleInit">

                <div class="property_pad" v-for="(property,index) in (inSalabam.data.results.length > 3 ? (inSalabam.data.results.slice(0,2)): inSalabam.data.results)" :key="'slide-monographic-'+index" :data-property_id="property.property_id">
                    <div class="postPad">
                    <div class="postPad__thumbnail" :style="'background-image:url('+property.thumbNailUrl+');'"><!--<span class="slickCredits" v-if="property.credits !== undefined">{{property.credits}}</span>--></div>
                    <div class="postPad__content">
                        <div>
                        <div class="postPad__title d-flex">
                            <SvgOccupancy />
                            <span>{{property.name}}</span>
                        </div>
                        <div class="property-pad__city">
                            <SvgPin />
                            {{property.address1}}, {{property.city}}
                        </div>
                        <div class="property__amenities">
                                                <span v-for="(value,index) in (Object.keys(property.amenities))" v-bind:key="value">
                                                    <span :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')"
                                                            v-if="index < 2">
                                                        {{amenitiesLabels[value].label}}
                                                    </span>
                                                </span>
                        </div>
                        </div>
                        <!--
                        <div class="postPad__link">
                            <a href="#" @click="inSalabam(property)">Scopri di più</a>
                        </div>
                        -->
                    </div>
                    </div>
                </div>

                <div class="infoPad">
                    <div class="background">
                    <div>
                        <h5 class="title"
                            @click="mixinGoTo('in-' + site.brand.toLowerCase(),{
                                                inSalabamId: inSalabam.infoPad.inSalabamId,
                                                inSalabamTitle: (inSalabam.infoPad.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
                                                setGeo: true,
                                            })">
                        {{inSalabam.infoPad.title}}
                        </h5>
                        <p class="abstract pointer">
                        {{inSalabam.infoPad.abstract}}
                        <span class="btn btn-sm link" @click="searchInSalabam()">Vedi le proposte</span>
                        </p>
                    </div>
                    </div>
                </div>

                <div class="property_pad" v-for="(property,index) in (inSalabam.data.results.length > 3 ? (inSalabam.data.results.slice(2,inSalabam.length)) : inSalabam.data.results)" :key="'slide-'+(index+2)" :data-property_id="property.property_id">
                    <div class="postPad">
                    <div class="postPad__thumbnail" :style="'background-image:url('+property.thumbNailUrl+');'"><!--<span class="slickCredits" v-if="property.credits !== undefined">{{property.credits}}</span>--></div>
                    <div class="postPad__content">
                        <div class="propertyRate">
                        <div class="postPad__title d-flex">
                            <SvgOccupancy />
                            <span>{{property.name}}</span>
                        </div>
                        <div class="property-pad__city">
                            <SvgPin />
                            {{property.address1}}, {{property.city}}
                        </div>
                        <div class="property__amenities">
                                                <span v-for="(value,index) in (Object.keys(property.amenities))" v-bind:key="value">
                                                    <span :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')"
                                                            v-if="index < 2">
                                                        {{amenitiesLabels[value].label}}
                                                    </span>
                                                </span>
                        </div>
                        </div>
                        <!--
                        <div class="postPad__link">
                            <a href="#" @click="inSalabam(property)">Scopri di più</a>
                        </div>
                        -->
                    </div>
                    </div>
                </div>

                </Slick>

                <span class="slick-arrow slick-prev" :id="'slick-prev-inSalabam'+_uid+'_m'">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                    <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>

                <span class="slick-arrow slick-next" :id="'slick-next-inSalabam'+_uid+'_m'">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                    <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </div>

            <!-- versione mobile: pad informativa fissa a destra -->
            <div class="slick desktop">
                <div class="slick-wrapper">

                    <Slick :ref="_uid+'_slick'" :options="slickOptionsDesktop" @init="handleInit">

                    <div class="property_pad" v-for="(property,index) in inSalabam.data.results" :key="'slide-'+(index+2)" :data-property_id="property.property_id">
                        <div class="postPad">
                        <div class="postPad__thumbnail" :style="'background-image:url('+property.thumbNailUrl+');'"><!--<span class="slickCredits" v-if="property.credits !== undefined">{{property.credits}}</span>--></div>
                            <div class="postPad__content">
                                <div class="propertyRate">
                                    <div class="postPad__title d-flex">
                                        <SvgOccupancy />
                                        <span>{{property.name}}</span>
                                    </div>
                                    <div class="property-pad__city">
                                        <SvgPin />
                                        {{property.address1}}, {{property.city}}
                                    </div>
                                    <div class="property__amenities">
                                        <span v-for="(value,index) in (Object.keys(property.amenities))" v-bind:key="value">
                                            <span :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')"
                                                    v-if="index < 2">
                                                {{amenitiesLabels[value].label}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </Slick>

                    <span class="slick-arrow slick-prev" :id="'slick-prev-inSalabam'+_uid+'_d'">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                        <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>

                    <span class="slick-arrow slick-next" :id="'slick-next-inSalabam'+_uid+'_d'">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                        <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>

                </div>

                <div class="infoPad-wrapper">
                    <div class="infoPad">
                        <div class="background">
                        <div>
                            <h5 class="title"
                                @click="mixinGoTo('in-' + site.brand.toLowerCase(),{
                                                    inSalabamId: inSalabam.infoPad.inSalabamId,
                                                    inSalabamTitle: (inSalabam.infoPad.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
                                                    setGeo: true,
                                                })">
                            {{inSalabam.infoPad.title}}
                            </h5>
                            <p class="abstract">
                                {{inSalabam.infoPad.abstract}}
                                <span class="abstract mb-0 pointer">
                                    <strong><u  @click="mixinGoTo('in-' + site.brand.toLowerCase(),{
                                        inSalabamId: inSalabam.infoPad.inSalabamId,
                                        inSalabamTitle: (inSalabam.infoPad.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
                                        setGeo: true,
                                    })">
                                    Scopri di più...
                                    </u></strong>
                                </span>
                                <span class="btn btn-sm link" @click="searchInSalabam()">Vedi le proposte</span>
                                </p>
                         </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div v-else></div>
  </div>
</template>

<script>
//import Api from '../../../api'
import Vue from 'vue'
import Spinner from '../../atoms/spinner'
import Slick from 'vue-slick'
//import SvgCalendar from '../../svg/calendar'
import SvgOccupancy from '../../svg/occupancy'
import SvgPin from '../../svg/pin'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
library.add(faSearch)

export default {
  name: 'inSalabamMonographicSlider',
  components: {
    Spinner,
    Slick,
    //SvgCalendar,
    SvgOccupancy,
    SvgPin,
  },
  props:
      {
        inSalabam: Object,
      },
  data() {
    return {
      site: this.appConfig().site,
      icons: Vue.prototype.$config.icons,
      amenitiesLabels:    Vue.prototype.$config.amenities,
      loading: true,
      slickOptionsDesktop: {
        infinite: true,
        //autoplay: (this.$config.guiSettings.autoplaySpeedInSalabam != 0),
        autoplaySpeed: this.$config.guiSettings.autoplaySpeedInSalabam,
        //vertical: true,
        //verticalSwiping: true,
        slidesToShow: 3,
        prevArrow: '#slick-prev-inSalabam'+this._uid+'_d',
        nextArrow: '#slick-next-inSalabam'+this._uid+'_d',
        adaptiveHeight:true,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 2,
            }
          },
          /*
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            }
          },
          
          {
            // sotto i 1200 viene nascosto
          }
          */
        ]
      },
      slickOptionsMobile: {
        infinite: true,
        //autoplay: (this.$config.guiSettings.autoplaySpeedInSalabam != 0),
        autoplaySpeed: this.$config.guiSettings.autoplaySpeedInSalabam,
        //vertical: true,
        //verticalSwiping: true,
        slidesToShow: 2,
        prevArrow: '#slick-prev-inSalabam'+this._uid+'_m',
        nextArrow: '#slick-next-inSalabam'+this._uid+'_m',
        adaptiveHeight:true,
        responsive: [
            /* 
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            }
          },
          */
          {
            breakpoint: 992,
            settings: {
              //adaptiveHeight:false,
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              //variableWidth: true,
              //vertical: false,
              //verticalSwiping: false,
            }
          }
        ]
      },
    }
  },
  mounted()
  {
    this.loading = false
  },
  methods:{
    handleInit(event, slick) {
        const [ slickTrack ] = slick.$slideTrack
        slickTrack.childNodes.forEach(slickSlide => {
            let _this = this
            slickSlide.onclick = function(e){
                let property_id = parseInt(e.target.closest('.property_pad').getAttribute('data-property_id'))
                let property = _this.inSalabam.data.results.filter(function (property) {
                    return property.property_id == property_id
                })[0]
                _this.goToProperty(property)
            }
        })
    },
    goToProperty(property)
    {
      this.mixinSendMutation('setGeo', {
        latitude: this.inSalabam.infoPad.geo.latitude,
        longitude: this.inSalabam.infoPad.geo.longitude,
        label: this.inSalabam.infoPad.geo.label,
        matchLevel: this.inSalabam.infoPad.geo.matchLevel,
        ignoreMapRedirect: true, // https://trello.com/c/7oWYmzkB/855-invadobay-slider-monografico
      })
      // rendo coerenti i dati nello store per eventuali ricerche successive
      this.mixinSendMutation('setTriggerSearch', true)
      this.mixinSendMutation('setCheckin', this.inSalabam.infoPad.search.checkin)
      this.mixinSendMutation('setCheckout', this.inSalabam.infoPad.search.checkout)
      this.mixinSendMutation('setAdults', this.inSalabam.infoPad.search.occupancy.adults)
      this.mixinSendMutation('setChildren', this.inSalabam.infoPad.search.occupancy.children)
      this.mixinSendMutation('setInSalabamId', this.inSalabam.infoPad.inSalabamId)

      this.mixinSendMutation('setPropertyDetail', property)

      this.mixinGoTo('property',{
        propertyId: property.property_id,
        propertyPad: property,
        propertyName: property.name,
        newWindow: false
      })

    },
    searchInSalabam()
    {
      //window.console.log('search:')
      //window.console.log(this.inSalabam.infoPad)
      this.mixinSendMutation('setGeo', {
        latitude: this.inSalabam.infoPad.geo.latitude,
        longitude: this.inSalabam.infoPad.geo.longitude,
        label: this.inSalabam.infoPad.geo.label,
        matchLevel: this.inSalabam.infoPad.geo.matchLevel,
      })
      this.mixinSendMutation('setTriggerSearch', true)
      this.mixinSendMutation('setCheckin', this.inSalabam.infoPad.search.checkin)
      this.mixinSendMutation('setCheckout', this.inSalabam.infoPad.search.checkout)
      this.mixinSendMutation('setAdults', this.inSalabam.infoPad.search.occupancy.adults)
      this.mixinSendMutation('setChildren', this.inSalabam.infoPad.search.occupancy.children)
      this.mixinSendMutation('setInSalabamId', this.inSalabam.infoPad.inSalabamId)

      this.mixinGoTo('search',{})
    },
  }
}
</script>

<style lang="scss">





.inSalabamMonographicSlider
{
    .sliderHeading svg,
    .slick-slide svg
    {
        min-width:24px;
        max-width:24px;
        margin-right:12px;
        margin-top:4px;
        transform:translateY(-4px);
    }

    .propertyRate
    {
        min-height: 100%;
        padding:0 0 0 0;
    }

    .postPad__content > div
    {
        min-height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @include media-breakpoint-up(lg)
    {
        .postSlider .slick .postPad .postPad__title
        {
        font-size: 16px;
        line-height: 110%;
        margin-bottom:0;//6px;
        }
        .postSlider .slick .postPad .postPad__content
        {
        padding-left: $grid-gutter-width/3;
        padding-right: $grid-gutter-width/3;
        padding-bottom: $grid-gutter-width/4;
        padding-top: $grid-gutter-width/4;
        }
    }

    .property__amenities
    {
        display:flex;
        & > span
        {
        max-width:50%;
        }
        .amenity
        {
        padding:0 9px;
        min-width: 100%;
        }
    }

    .amenity
    {
        width:auto;
        min-width:auto;
        font-size: 12px;
        margin-bottom:0;
    }

    // creo spazio per poter aggiungere indirizzo alla pad
    .postPad__thumbnail
    {
        max-height: 65%;
    }
    .property-pad__city
    {
        font-size: 12px;
        display:flex;
        svg
        {
        transform:translateY(2px);
        margin:0 6px 0 0;
        //margin:0 8px 0 0;
        //min-width: 24px;
        //max-width: 24px;
        path
        {
            fill:$black;
        }
        }
    }

    @include media-breakpoint-down(sm)
    {
        .mobileNewline
        {
        flex:100%;
        margin-top:10px;
        }
        .mobileHide
        {
        //font-size: 14px;
        //margin-bottom:10px;
        display: none;
        }
    }


    .postPad__content
    {
        //margin:0 !important;
        @include media-breakpoint-up(lg)
        {
            margin:0 !important;
        }
    }


    // pad informativa sempre visibile su slider desktop
    .slick.mobile
    {
        display:block;
    }
    .slick.desktop 
    {
        display:none;
    }    

    .abstract .abstract
    {
        //min-height: 200px;
        //display:none;
        padding:0 !important;
        white-space: nowrap;
        @include customize-color("cta");
    }

    .infoPad
    {
        .background
        {
            @if ($customize)
            {
                @include customize-gradient(vertical, important);
            }
        }
    }

    @include media-breakpoint-up(xl)
    {
        .slick.mobile
        {
            display:none;
        }
        .slick.desktop 
        {
            display:flex;
            .slick-wrapper
            {
                width:calc(100%/3*2 - 15px);
                .slick-slider
                {
                    width:100%;
                    max-width: 100%;
                    margin:0;
                    padding-left: 50px;
                }

                .slick-slider,
                .slick-list,
                .slick-track
                {
                    height:100%;
                }
            }
            .infoPad-wrapper
            {
                width:calc(100%/3 + 15px);
                margin:0;
                padding:0 80px 0 30px;
                .infoPad
                {
                }   
            }
        }
    }

    @include media-breakpoint-up(xxl)
    {
        .slick-wrapper
        {
            width:calc(100%/4*3 - 30px) !important;//la media query @include media-breakpoint-up(xl) degli slider caricati successivamente sovrascrive questa, quindi va lasciata !important
        }
        .infoPad-wrapper
        {
            width:calc(100%/4 + 30px) !important;
        }
    }

    .title,
    .abstract
    {
        @include customize-gradient-text(important);
    }

}

</style>